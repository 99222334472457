<template>
  <el-dialog
    title="格位调整"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="80%"
    @close="$emit('refreshDataList')"
  >
    <el-form ref="shops" :model="shops" label-width="80px">
      <el-form-item label="智能取餐柜名字" prop="name">
        {{ shops.name }}
      </el-form-item>
    </el-form>
    <div class="content">
      <div
        v-for="i in range(shops.shelf).reverse()"
        :key="i"
        class="shelf-wrapper"
      >
        <p class="shelf">{{ i + 1 }}号架</p>
        <table>
          <tr>
            <td
              v-for="k in range(shops.space).reverse()"
              :key="i + '-' + k"
              class="space"
            >
              {{ k + 1 }}号仓位
            </td>
          </tr>
          <tr v-for="j in range(shops.storey).reverse()" :key="j">
            <td
              v-for="k in range(shops.space).reverse()"
              :key="j + '-' + k"
              class="cell"
            >
              <div
                v-for="code in [i + 1 + ('0' + (j + 1)).slice(-2) + (k + 1)]"
                :key="code"
              >
                <div class="info">
                  <div class="code">
                    {{ code }}
                  </div>
                  <goods-code-popover
                    v-for="goods in goodsCodeMap.get(code)"
                    :key="goods.id"
                    :goods="goods"
                    :update-code-id="updateCodeId"
                    :highlight-goods-id="highlightGoodsId"
                  >
                    <el-button-group>
                      <el-button
                        v-if="updateCodeId == null"
                        size="mini"
                        type="primary"
                        @click="preUpdateCode(goods)"
                      >
                        移位
                      </el-button>
                      <el-button
                        v-else
                        size="mini"
                        type="primary"
                        @click="updateCodeId = null"
                      >
                        取消
                      </el-button>
                      <el-button
                        size="mini"
                        type="danger"
                        @click="deleteGoods(goods.id)"
                      >
                        删除
                      </el-button>
                    </el-button-group>
                  </goods-code-popover>
                </div>
                <el-button
                  :style="{
                    visibility: updateCodeId != null ? 'visible' : 'hidden',
                  }"
                  size="mini"
                  type="primary"
                  :disabled="!!goodsCodeMap.get(code)"
                  @click="updateCode(code)"
                >
                  入柜
                </el-button>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="shelf-wrapper">
        <p class="shelf">未入柜餐品</p>
        <el-button
          :style="{ visibility: updateCodeId != null ? 'visible' : 'hidden' }"
          size="mini"
          type="primary"
          @click="updateCode('')"
        >
          出柜
        </el-button>
        <div class="no-code">
          <goods-code-popover
            v-for="goods in goodsCodeMap.get(null)"
            :key="goods.id"
            :goods="goods"
            :update-code-id="updateCodeId"
            :highlight-goods-id="highlightGoodsId"
          >
            <el-button-group>
              <el-button
                v-if="updateCodeId == null"
                size="mini"
                type="primary"
                @click="updateCodeId = goods.id"
              >
                移位
              </el-button>
              <el-button
                v-else
                size="mini"
                type="primary"
                @click="updateCodeId = null"
              >
                取消
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="deleteGoods(goods.id)"
              >
                删除
              </el-button>
            </el-button-group>
          </goods-code-popover>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import GoodsCodePopover from './goodscode-popover';

export default {
  components: { GoodsCodePopover },
  data() {
    return {
      visible: false,
      shops: {
        id: '',
        name: '',
        shelf: 2,
        storey: 15,
        space: 4,
      },
      highlightGoodsId: null,
      updateCodeId: null,
      goodsCodeList: [],
    };
  },
  computed: {
    validCodes() {
      const codes = new Set();
      for (let i = 0; i < this.shops.shelf; i++) {
        for (let j = 0; j < this.shops.storey; j++) {
          for (let k = 0; k < this.shops.space; k++) {
            const code = i + 1 + ('0' + (j + 1)).slice(-2) + (k + 1);
            codes.add(code);
          }
        }
      }
      return codes;
    },
    goodsCodeMap() {
      const map = new Map();
      for (const goodsCode of this.goodsCodeList) {
        let code = goodsCode.code;
        if (goodsCode.orderId === '' && goodsCode.isPresale) {
          continue;
        }
        if (!this.validCodes.has(code)) {
          code = null;
        }
        const entry = map.get(code);
        if (entry) {
          entry.push(goodsCode);
        } else {
          map.set(code, [goodsCode]);
        }
      }
      return map;
    },
  },
  methods: {
    range(n) {
      return [...Array(n).keys()];
    },
    init(goodsId, shopsId) {
      this.shops.id = shopsId || '';
      this.highlightGoodsId = goodsId || '';
      this.goodsCodeList = [];
      this.updateCodeId = null;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['shops'].resetFields();
        this.$http({
          url: `/mall/shops/info/${this.shops.id}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.shops = data.shops;
          }
        });
        this.$http({
          url: `/mall/goodscode/queryAll`,
          method: 'post',
          data: {
            shopsId: this.shops.id,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.goodsCodeList = data.list;
          }
        });
      });
    },
    // 删除商品
    deleteGoods(id) {
      this.$confirm(`确定对[id=${id}]进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http({
          url: `/mall/goodscode/delete/${id}`,
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
            });
            this.goodsCodeList = this.goodsCodeList.filter(
              (item) => item.id !== id,
            );
          }
        });
      });
    },
    // 修改格位
    preUpdateCode(goods) {
      if (goods.orderId) {
        this.$confirm(
          `确定对已下单餐品[id=${goods.id}]进行[移位]操作?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        ).then(() => {
          this.updateCodeId = goods.id;
        });
      } else {
        this.updateCodeId = goods.id;
      }
    },
    // 修改格位
    updateCode(code) {
      const goods = this.goodsCodeList.find(
        (item) => item.id === this.updateCodeId,
      );
      if (goods) {
        if (goods.code === code) {
          this.updateCodeId = null;
          return;
        }
        this.$http({
          url: `/mall/goodscode/update`,
          method: 'post',
          data: {
            id: this.updateCodeId,
            code,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
            });
            // const entry = this.goodsCodeMap.get(code)
            // if (entry) {
            //   entry.forEach(item => (item.code = goods.code))
            // }
            goods.code = code;
            this.updateCodeId = null;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-code {
  ::v-deep .el-tag {
    margin: 4px;
  }
}

.content {
  display: flex;
}

.shelf-wrapper {
  display: inline-block;
  margin: 16px;

  .shelf {
    text-align: center;
    font-weight: bold;
  }

  .space {
    text-align: center;
  }

  td {
    border: 1px solid black;
  }
}

.cell {
  .info {
    padding: 8px;
  }

  .code {
    font-size: 8px;
    color: darkred;
  }

  img {
    width: 30px;
    height: 30px;
  }

  .operate {
    text-align: center;
  }
}
</style>
